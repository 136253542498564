

const OriginalImg1 = "https://i.ibb.co/Z2YDPPV/aviator.png"
const OriginalImg2 = "https://i.ibb.co/L6js6PF/hotimg5.png"
const OriginalImg3 = "https://i.ibb.co/DwBBZ1z/hotimg6.png"
const OriginalImg4 = "https://i.ibb.co/d0gLnV9/wingo2.png"
const OriginalImg5 = "https://i.ibb.co/PThTb8V/hourse-racing.png"
const OriginalImg6 = "https://i.ibb.co/02pDChW/triple.png"
const OriginalImg7 = "https://i.ibb.co/LhQtZcM/cryptos.png"
const OriginalImg8 = "https://i.ibb.co/Krd4Cd9/coin-flip.png"
const OriginalImg9 = "https://i.ibb.co/5Wb9vT7/hotline.png"
const OriginalImg10 = "https://i.ibb.co/n83h4vM/keno.png"
const OriginalImg11 = "https://i.ibb.co/L5d9DwL/goal.png"
const OriginalImg12 = "https://i.ibb.co/H297qgQ/miniroulette.png"
const OriginalImg13 = "https://i.ibb.co/NyB559B/dice.png"
const OriginalImg14 = "https://i.ibb.co/k8MTW1J/hilo.png"
const OriginalImg15 = "https://i.ibb.co/f0MJ9pr/mines.png"
const OriginalImg16 = "https://i.ibb.co/fvW4gj2/keno80.png"
const OriginalImg17 = "https://i.ibb.co/JnzmPBT/aviator2.png"
const OriginalImg18 = "https://i.ibb.co/zQ03Xvk/dice2.png"
const OriginalImg19 = "https://i.ibb.co/4NL35vd/goal2.png"
const OriginalImg20 = "https://i.ibb.co/kxNRZLF/plinko2.png"
const OriginalImg21 = "https://i.ibb.co/RbdmFdY/mines2.png"
const OriginalImg22 = "https://i.ibb.co/fMLBBs3/hilo2.png"
const OriginalImg23 = "https://i.ibb.co/DPF9xX3/keno2.png"
const OriginalImg24 = "https://i.ibb.co/1qQC0RZ/mini-roulette2.png"
const OriginalImg25 = "https://i.ibb.co/8PpDbdG/color-game.png"
const OriginalImg26 = "https://i.ibb.co/tLnKnsH/pappu.png"

const HotImg1 = "https://i.ibb.co/cFYv5Xf/hotimg1.png"
const HotImg2 = "https://i.ibb.co/JCSbWfg/hotimg2.png"
const HotImg3 = "https://i.ibb.co/27cp91p/hotimg3.png"
const HotImg4 = "https://i.ibb.co/Z2YDPPV/aviator.png"
const HotImg5 = "https://i.ibb.co/L6js6PF/hotimg5.png"
const HotImg6 = "https://i.ibb.co/DwBBZ1z/hotimg6.png"


const CasinoImg1 = "https://i.ibb.co/yn5Mt4M/casinoimg1.png"
const CasinoImg2 = "https://i.ibb.co/jJpHgKL/casinoimg2.png"
const CasinoImg3 = "https://i.ibb.co/WKWyjbc/casinoimg3.png"
const CasinoImg4 = "https://i.ibb.co/M9ShKWh/casinoimg4.png"
const CasinoImg5 = "https://i.ibb.co/D8SHm4J/casinoimg5.png"
const CasinoImg6 = "https://i.ibb.co/W2q1vzY/casinoimg6.png"


const SlotsImg1 = "https://i.ibb.co/PzQVXZq/slotsimg1.png"
const SlotsImg2 = "https://i.ibb.co/71GKjTF/slotsimg2.png"
const SlotsImg3 = "https://i.ibb.co/rdcrKdR/slotsimg3.png"
const SlotsImg4 = "https://i.ibb.co/CsPrT4t/slotsimg4.png"
const SlotsImg5 = "https://i.ibb.co/ZTy8mYd/slotsimg5.png"
const SlotsImg6 = "https://i.ibb.co/D18YJZD/slotsimg6.png"
const SlotsImg7 = "https://i.ibb.co/5MkBHKX/slotsimg7.png"
const SlotsImg8 = "https://i.ibb.co/1Zx0jgG/slotsimg8.png"
const SlotsImg9 = "https://i.ibb.co/94xf1sK/slotsimg9.png"

export const originalData = [
  {
    Img1: OriginalImg1,
    Img2: OriginalImg2,
    Img3: OriginalImg3
  },
  {
    Img1: OriginalImg4,
    Img2: OriginalImg5,
    Img3: OriginalImg6
  },
  {
    Img1: OriginalImg7,
    Img2: OriginalImg8,
    Img3: OriginalImg9
  },
  {
    Img1: OriginalImg10,
    Img2: OriginalImg11,
    Img3: OriginalImg12
  },
  {
    Img1: OriginalImg13,
    Img2: OriginalImg14,
    Img3: OriginalImg15
  },
  {
    Img1: OriginalImg16,
    Img2: OriginalImg17,
    Img3: OriginalImg18
  },
  {
    Img1: OriginalImg19,
    Img2: OriginalImg20,
    Img3: OriginalImg21
  },
  {
    Img1: OriginalImg22,
    Img2: OriginalImg23,
    Img3: OriginalImg24
  },
  {
    Img1: OriginalImg25,
    Img2: OriginalImg26,
  },

]
export const HotGameData = [
  {
    Img: HotImg1,
  },
  {
    Img: HotImg2,
  },
  {
    Img: HotImg3,
  },
  {
    Img: HotImg4,
  },
  {
    Img: HotImg5,
  },
  {
    Img: HotImg6,
  },
]

export const CasinoGameData = [
  {
    Img: CasinoImg1,
  },
  {
    Img: CasinoImg2,
  },
  {
    Img: CasinoImg3,
  },
  {
    Img: CasinoImg4,
  },
  {
    Img: CasinoImg5,
  },
  {
    Img: CasinoImg6,
  },
]

export const SlotsGameData = [
  {
    Img1: SlotsImg1,
    Img2: SlotsImg2,
    Img3: SlotsImg3,
    Img4: SlotsImg4,
    Img5: SlotsImg5,
    Img6: SlotsImg6,
  },
  {
    Img1: SlotsImg7,
    Img2: SlotsImg8,
    Img3: SlotsImg8,

  },
]